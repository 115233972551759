<template>
  <b-form
    v-if="$can('all', 'user')"
    ref="form"
    @submit.prevent="addUser"
  >
    <b-row>

      <!-- first name -->
      <b-col md="6">
        <b-form-group
          label="İsim"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="form.name"
              required
              placeholder="First Name"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- email -->
      <b-col md="6">
        <b-form-group
          label="Email"
          label-for="vi-email"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="MailIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-email"
              v-model="form.email"
              required
              type="email"
              placeholder="Email"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- mobile -->
      <b-col md="6">
        <b-form-group
          label="İban"
          label-for="vi-iban"
        >

          <b-form-input
            id="vi-iban"
            v-model="form.iban"
            placeholder="Iban"
          />
        </b-form-group>
      </b-col>

      <!-- password -->
      <b-col md="6">
        <b-form-group
          label="Şifre"
          label-for="vi-password"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="LockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-password"
              v-model="form.password"
              type="password"
              required
              placeholder="Password"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- checkbox -->
      <b-col md="12">
        <b-form-group>
          <b-form-checkbox
            id="checkbox-4"
            v-model="form.archived"
            name="checkbox-4"
          >
            Arşiv
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- reset and submit -->
      <b-col md="6">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Ekle
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        name: null,
        archived: false,
        iban: null,
      },
    }
  },
  created() {
    if (!this.$can('all', 'user')) {
      this.$router.push({ name: 'error-404' })
    }
  },
  methods: {
    ...mapActions('auth', ['createUser']),
    async addUser() {
      await this.createUser(this.form)
        .then(res => {
          if (res.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$refs.form.reset()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarısız',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
